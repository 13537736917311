import React from "react";
import theme from "theme";
import { Theme, Icon, Text, Box, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdEmail, MdPhone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Velocity Wheels Araç Kiralama
			</title>
			<meta name={"description"} content={"Yolculuğunuzu Kendi Yolunuzla Keşfedin - Velocity Wheels Bunu Mümkün Kılıyor\n"} />
			<meta property={"og:title"} content={"Velocity Wheels Araç Kiralama"} />
			<meta property={"og:description"} content={"Yolculuğunuzu Kendi Yolunuzla Keşfedin - Velocity Wheels Bunu Mümkün Kılıyor\n"} />
			<meta property={"og:image"} content={"https://gemrassa.com/img/0.jpg"} />
			<link rel={"shortcut icon"} href={"https://gemrassa.com/img/2389246.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://gemrassa.com/img/2389246.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://gemrassa.com/img/2389246.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://gemrassa.com/img/2389246.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://gemrassa.com/img/2389246.png"} />
			<meta name={"msapplication-TileImage"} content={"https://gemrassa.com/img/2389246.png"} />
		</Helmet>
		<Components.Header />
		<Section
			color="--light"
			padding="100px 0"
			sm-padding="40px 0"
			position="relative"
			background="linear-gradient(0deg,rgba(25, 30, 34, 0.8) 0%,rgba(25, 30, 34, 0.8) 100%),--color-darkL2 url(https://gemrassa.com/img/1.jpg) 0% 50%/cover"
			quarkly-title="Form-1"
		>
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="50%" md-width="100%">
					<Box>
						<Box
							sm-padding="64px 0 0 0"
							margin="32px 0 0 0"
							max-width="360px"
							position="relative"
							padding="0 0 0 64px"
						>
							<Icon
								size="48px"
								top="0"
								left="0"
								category="md"
								icon={MdLocationOn}
								position="absolute"
							/>
							<Text as="h4" margin="6px 0" font="--lead">
								Bahçekapı, 2551. Cadde, 2526. Sk. Şaşmaz Oto Sanayi Sitesi No:1/F
					
						06105 Etimesgut/Ankara, Türkiye
							</Text>
						</Box>
						<Box
							position="relative"
							padding="0 0 0 64px"
							sm-padding="64px 0 0 0"
							margin="64px 0 0 0"
							max-width="360px"
						>
							<Icon
								top="0"
								left="0"
								category="md"
								icon={MdEmail}
								position="absolute"
								size="48px"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Bize e-posta gönderin
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								<Link href="mailto:contact@gemrassa.com" text-decoration="none" hover-text-decoration="underline" color="--light">
									contact@gemrassa.com
								</Link>
							</Text>
						</Box>
						<Box padding="0 0 0 64px" margin="64px 0 0 0" max-width="360px" position="relative">
							<Icon
								left="0"
								category="md"
								icon={MdPhone}
								position="absolute"
								size="48px"
								top="0"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Bizi arayın
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								+90 312 278 5042
							</Text>
						</Box>
					</Box>
				</Box>
				<Box width="50%" padding="8px 8px 8px 8px" md-width="100%">
					<Text
						margin="50px 0px 30px 0px"
						font="--headline1"
						color="--light"
						text-align="center"
						sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						İletişim{"  "}
					</Text>
					<Text margin="0px 0px 48px 0px" font="--lead" color="--light" text-align="center">
						Hizmetlerimiz, filomuz ve sunduğumuz benzersiz kiralama deneyimi hakkında daha fazla bilgi için.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});